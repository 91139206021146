.gm-style-cc {
  display: none;
}

.device-map {
  font-family: "TELSTRAAKKURAT", Arial, Helvetica, sans-serif;
  background-color: #f0f0f0;
  margin: 2px 0px 10px 0px;
  box-sizing: border-box;
  width: auto;
  height: inherit;
}
.device-map .search-box-control {
  display: block;
}
.device-map.isMobile {
  margin: 2px 0px 0px 0px;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}
.device-map.isMobile .device-map {
  position: absolute !important;
  width: 100%;
}
.device-map.isMobile div[aria-label="Map Application"] {
  width: 100%;
  height: inherit;
}

.mapLoadingOverlay {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.device-map.hide .search-box-control {
  display: none;
}
.device-map.hide .popup-tip-anchor {
  display: none !important;
}

.loadingText {
  object-fit: contain;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #0095f2;
}

/*
  Customized styles for the Auto address complete list
*/
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
  background-image: none !important;
  height: 0px;
}

.pac-item .pac-icon-marker {
  /* marker to be suppressed */
  display: none;
}

button.showHideMap {
  width: 100px;
  height: 20px;
  border-radius: 13px;
  font-size: 11px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
  color: #414141;
  position: relative;
  top: -10px;
  outline: none;
  cursor: pointer;
  z-index: 1000;
}

.tracker-detail {
  -webkit-border-horizontal-spacing: 5px;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .tracker-detail {
    display: none;
  }
}

.tracker-detail-body {
  width: 100%;
  -webkit-border-vertical-spacing: 5px;
}
.tracker-detail-body .battery {
  margin-inline-start: auto;
  margin-right: 54px;
}
.tracker-detail-body .battery .batteryText {
  font-size: 14px;
  min-width: auto;
  font-weight: bold;
  margin-right: -36px;
}

.tracker-detail-body-row {
  width: 100%;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 8px;
  vertical-align: middle;
}

.tracker-detail-column {
  vertical-align: middle;
  font-family: TelstraAkkurat, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #000000;
  display: table-cell;
  max-width: 160px;
  min-width: 135px;
  text-align: left;
}

.tracker-detail-cell-heading {
  font-size: 8px;
  font-weight: bold;
}

.tracker-detail-cell-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 5px;
}

.tracker-detail-title {
  width: 100%;
  height: 25px;
  background-color: #0064d2;
  font-family: TelstraAkkurat, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #ffffff;
  display: table;
  padding-bottom: 5px;
  padding-top: 5px;
}

.tracker-detail-title-id-label {
  display: table-cell;
  vertical-align: middle;
}

.tracker-detail-title-more-label {
  font-weight: bold;
  display: table-cell;
  vertical-align: middle;
}

.tracker-detail-title-cell {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  max-width: 250px;
  min-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  margin-left: 8px;
}

.popup-tip-anchor {
  height: 0;
  position: absolute;
  /* The max width of the info window. */
}
@media screen and (max-width: 600px) {
  .popup-tip-anchor {
    display: none;
  }
}

/* The bubble is anchored above the tip. */
.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom: 3px;
  left: 0;
}
@media screen and (max-width: 600px) {
  .popup-bubble-anchor {
    display: none;
  }
}

/* Draw the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-45%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 20px wide. */
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 8px solid white;
}

/* The popup bubble itself. */
.popup-bubble-content {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: white;
  height: auto;
  max-height: 200px;
  box-shadow: 0 2.5px 4.5px 1.5px rgba(0, 0, 0, 0.14);
}

/*      //NOTE: Keep these in sync a) config.js=>mapPopup.controls.search, b)search-component=>styles.searchComponent c)devicemap.scss =>.search-box-control {
*/
.search-box-control {
  margin-left: 20px;
  margin-top: 9px;
  height: 30px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.07);
}

.search-box {
  width: 100%;
  height: 100%;
  font-family: TelstraAkkurat, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: 10px;
  padding-left: 40px;
}

.hide-button-div {
  text-align: center;
  height: 0px;
}

.map-controls-div {
  width: 33px;
  height: 135px;
  margin-bottom: 10px;
  background-repeat: no-repeat;
}

.create-geozone-info {
  cursor: pointer;
}

.create-geozone-info-popup {
  width: 190px;
  height: 100%;
  padding: 10px;
  white-space: initial;
  font-size: 13px;
  cursor: pointer;
}

.map-drag-component {
  text-align: center;
  z-index: 2;
  position: relative;
}

.routesWithMap .device-map .level-control {
  display: none;
}

.history-map:not(.isMobile) #azure-map .level-control {
  display: none;
}

.level-control .azure-maps-control-button.selected {
  border: solid 2px #0064d2;
  border-radius: 5px;
}
.level-control .azure-maps-control-button.selected:focus {
  outline: none;
}

.history-map:not(.isMobile) #azure-map .level-control {
  display: none;
}

.device-map.isMobile .atlas-map .bottom-right.subcontrol-container,
.device-map .atlas-map .bottom-right.subcontrol-container,
#azure-map .atlas-map .bottom-right.subcontrol-container {
  margin-bottom: 20px !important;
}
.device-map.isMobile .bottom-right,
.device-map .bottom-right,
#azure-map .bottom-right {
  bottom: 5px !important;
}

.azure-maps-control-container[aria-label="Map Style Control"] {
  display: none;
}

