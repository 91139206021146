/* Set Column and corresponding data field width */
/*Set !important to force width, else its overriden by inline width style */
.devicesFilterTitle {
  display: block;
  background-color: #0064d2;
  height: 35px;
  line-height: 35px;
  padding-left: 23px;
  font-size: 15px;
  color: #ffffff;
}

.inactiveTrackersLabel,
.mapBoundaryFilterLabel {
  width: 8em;
}

.inactiveTrackersMapViewSwitch .filterSwitchRows {
  padding-bottom: 0px;
}

.devicesFilterComponent {
  padding: 16px;
  background-color: #fff;
}
.devicesFilterComponent .filterSection {
  padding: 12px;
}
.devicesFilterComponent .userActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}
.devicesFilterComponent .userActions .inactiveTrackersLabel,
.devicesFilterComponent .userActions .mapBoundaryFilterLabel {
  margin-right: 8px;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
}
.devicesFilterComponent .userActions .mapBoundaryFilterSwitch {
  padding-bottom: 0px;
  padding-top: 0px;
}
.devicesFilterComponent .userActions .buttonsSection {
  display: flex;
  flex-direction: row;
  margin-right: 24px;
}
.devicesFilterComponent .userActions .message.error {
  color: red;
  font-weight: bold;
  padding: 0px 30px;
}
.devicesFilterComponent .userActions .filterSwitch {
  justify-content: space-between;
  min-width: 200px;
}
.devicesFilterComponent .alignClearButton div:first-child {
  display: flex;
}
.devicesFilterComponent .alignClearButton #mui-component-select-deviceBatteryFilterComponent,
.devicesFilterComponent .alignClearButton #mui-component-select-deviceTypeFilterComponent,
.devicesFilterComponent .alignClearButton #mui-component-select-geoZonesFilterCondition,
.devicesFilterComponent .alignClearButton #mui-component-select-numericFilterComponent {
  width: 100%;
  padding-right: 0;
  z-index: 9;
  color: #0064d2;
}

.devicesFilterComponent.isMobile {
  display: flex;
  flex-direction: column;
  padding: 30px 16px 0px 16px;
  position: relative;
  overflow: scroll;
  height: 85%;
}
@media (orientation: landscape) {
  .devicesFilterComponent.isMobile {
    position: absolute !important;
  }
}
.devicesFilterComponent.isMobile .filterSection {
  padding: 0px 15px 15px !important;
}
@media (orientation: landscape) {
  .devicesFilterComponent.isMobile .filterSection {
    padding: 0px 13px 13px !important;
  }
}
.devicesFilterComponent.isMobile .includeInactiveTrackersWrapperMobile {
  margin-left: 0px;
  width: 100%;
  padding-bottom: 8px;
  align-items: start;
}
.devicesFilterComponent.isMobile .inactiveTrackersLabelMobile {
  height: 12px;
  font-size: 18px;
  color: #000000;
  text-align: left;
  margin-bottom: 8px;
  display: flex;
  width: 45%;
  justify-content: flex-start;
}
.devicesFilterComponent.isMobile .inactiveTrackerControlMobile {
  margin-top: 5px;
}

.devicesFilterComponent.isMobile.isAndroid {
  height: 80%;
}
@media (orientation: landscape) {
  .devicesFilterComponent.isMobile.isAndroid {
    position: absolute !important;
  }
}

.devicesFilterComponent.isMobile.showFilter {
  height: 70%;
}
@media (orientation: landscape) {
  .devicesFilterComponent.isMobile.showFilter {
    padding-top: 64px;
  }
}

.filterSection.high-contrast .title {
  font-size: 18px;
}
.filterSection.high-contrast .dateFilterComponent .label,
.filterSection.high-contrast .dateFilterComponent .disabled {
  font-size: 16px;
  color: #000000;
}
.filterSection.high-contrast .dateFilterComponent ::placeholder {
  color: #0064d2;
}

.inactiveTrackersMapViewSwitch > .toggle-label,
.inactiveTrackersMapViewSwitch .inactiveTrackersState {
  font-weight: bold;
}

.filterSectionComponent {
  padding-right: 10px;
  border-right: solid 1px #dddddd;
  height: 100%;
}
.filterSectionComponent .header {
  height: 16px;
}
.filterSectionComponent .title,
.filterSectionComponent .clear {
  display: inline-block;
}
.filterSectionComponent .clear {
  float: right;
  text-align: right;
  font-size: 16px;
  font-weight: bold;
  color: #0095f2;
  height: 16px;
  line-height: 16px;
}
.filterSectionComponent .clear button {
  /* Override common button small properties */
  font-size: 16px !important;
  line-height: 16px;
  font-weight: bold !important;
  padding: 0px;
}
.filterSectionComponent .title {
  line-height: 16px;
  float: left;
  color: #414141;
  height: 16px;
  font-size: 18px;
  font-weight: bold;
}
.filterSectionComponent .title.isMobile {
  line-height: 16px;
  float: left;
  color: #000000;
  font-size: 18px;
  font-weight: bold;
}
.filterSectionComponent .tagsContainer.mobile {
  position: relative;
  top: -38px;
}
.filterSectionComponent .tagsContainer.mobile .tagCell {
  max-height: 290px;
  overflow-x: hidden;
  overflow-y: scroll;
}
@media only screen and (min-width: 768px) and (max-width: 1199px) and (orientation: portrait) {
  .filterSectionComponent .tagsContainer.mobile .tagCell {
    max-height: none;
  }
}
.filterSectionComponent .tagsContainer .tagCell {
  word-break: break-word;
  max-height: 200px;
  overflow: auto;
}
.filterSectionComponent .tagsContainer .tagCell span {
  margin-top: 2px;
  word-wrap: normal;
  display: inline-block;
  border: solid 1.2px #747474;
  margin-right: 10px;
  padding: 2px 7px;
}
.filterSectionComponent .tagsContainer .tagCell span:hover {
  cursor: pointer;
}
.filterSectionComponent .tagsContainer .tagCell span.isMobile {
  background-color: white;
}
.filterSectionComponent .tagsContainer .tagCell span.truncateTags {
  max-width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 2px 5px 3px 0px;
}
.filterSectionComponent .tagsContainer .tagCell span.selected {
  background-color: #4a90e2;
  border-color: #4a90e2;
  color: #fff;
}
.filterSectionComponent .tagsContainer .tagCell span.selected.isMobile {
  background-color: #0099f8;
  border-color: #0099f8;
  color: #fff;
}
.filterSectionComponent .tagsContainer .tagCell span.disabled {
  color: #dddddd;
  border-color: #dddddd;
}
.filterSectionComponent .tagsContainer .tagCell span.disabled:hover {
  cursor: auto;
}
.filterSectionComponent .customArrow {
  display: inline-block;
  width: 20px;
  height: 14px;
  margin: 0 3px;
  vertical-align: middle;
  background-image: url("../../images/drop-down-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  outline: none;
}

.filterSectionComponent.withNoBorder {
  border-right: 0px;
}

.filterSectionComponent.withBottomBorder {
  border-bottom: solid 1px #0064d2;
  padding-bottom: 5px;
  border-right: 0px;
  padding-right: 0px;
}

.tagsAndOrLabel {
  margin-left: 0px !important;
  margin-right: 10px !important;
}
.tagsAndOrLabel span:first-child {
  height: 32px;
}

.filterComponent {
  margin-top: 21px;
}
.filterComponent .label {
  height: 12px;
  font-size: 16px;
  color: #414141;
  text-align: left;
  margin-top: 21px;
  margin-bottom: 8px;
  font-weight: bold;
}
.filterComponent .label:first-child {
  margin-top: 0px;
}
.filterComponent .label.disabled {
  color: #dddddd;
}

.filterComponentMobile {
  margin-top: 0px;
}
.filterComponentMobile .label {
  height: 12px;
  font-size: 18px;
  color: #000000;
  text-align: left;
  margin-top: 21px;
  margin-bottom: 8px;
  display: flex;
  width: 45%;
  justify-content: flex-start;
}
.filterComponentMobile .label:first-child {
  margin-top: 0px;
}
.filterComponentMobile .label.disabled {
  color: #dddddd;
}

.filterComponentMobile .tagsLabel {
  height: 12px;
  font-size: 13px;
  color: #414141;
}

.deviceTypeFilterComponent,
.deviceBatteryFilterComponent,
.numericFilterComponent {
  margin-top: 21px;
}
.deviceTypeFilterComponent .label,
.deviceBatteryFilterComponent .label,
.numericFilterComponent .label {
  height: 12px;
  font-weight: bold;
  font-size: 16px;
  color: #414141;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 8px;
}
.deviceTypeFilterComponent .label.disabled,
.deviceBatteryFilterComponent .label.disabled,
.numericFilterComponent .label.disabled {
  color: #dddddd;
}

.filterSelect.disabled > div {
  color: #dddddd;
}

.numericFilterComponentMobile {
  display: flex;
  width: 100%;
  margin-top: 10px;
}
.numericFilterComponentMobile .label {
  height: 12px;
  font-size: 18px;
  color: #000000;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 8px;
  display: flex;
  width: 45%;
  justify-content: flex-start;
}
.numericFilterComponentMobile .label.disabled {
  color: #dddddd;
}

.numericFilterInput {
  padding: 6px 0 7px !important;
  color: #0064d2 !important;
}

.numericFilterInputMobile {
  padding: 12px 0 13px !important;
  text-align: center;
}
.numericFilterInputMobile#number-input {
  width: 80px;
}

.dateFilterComponent .message {
  height: 19px;
  font-size: 13px;
  font-weight: normal;
  color: red;
  border: none;
  margin-top: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
}
.dateFilterComponent .react-datepicker-popper {
  z-index: 99;
}
.dateFilterComponent .react-datepicker__input-container input {
  color: #0064d2;
}
.dateFilterComponent.disabled input::placeholder {
  color: #dddddd;
}

.geozones-filter-component-dropdown {
  margin-top: 3px;
}

