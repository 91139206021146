/* Set Column and corresponding data field width */
.mobileDeviceListPaper .deviceFriendlyNameColumnHeader,
.mobileDeviceListPaper .deviceFriendlyNameColumn {
  width: 47%;
}
.mobileDeviceListPaper .deviceFriendlyNameColumn div,
.mobileDeviceListPaper .deviceFriendlyNameColumnHeader div {
  width: 100%;
  font-weight: bold;
  font-size: 13px;
  padding: 0px 0px 0px 24px;
}
.mobileDeviceListPaper .assetDistanceColumn,
.mobileDeviceListPaper .assetDistanceColumnHeader {
  width: 18%;
}
.mobileDeviceListPaper .assetDistanceColumn div,
.mobileDeviceListPaper .assetDistanceColumnHeader div {
  font-size: 13px;
  color: #747474;
  padding: 0px 0px 0px 4px;
}
.mobileDeviceListPaper .lastSingalDateTimeColumn,
.mobileDeviceListPaper .lastSingalDateTimeColumnHeader {
  width: 25%;
}
.mobileDeviceListPaper .lastSingalDateTimeColumn,
.mobileDeviceListPaper .lastSingalDateTimeColumnHeader {
  /* Placeholders shown in virtualized table when loading initial set of rows */
}
.mobileDeviceListPaper .lastSingalDateTimeColumn div,
.mobileDeviceListPaper .lastSingalDateTimeColumnHeader div {
  font-size: 13px;
  color: #747474;
  padding: 0px 0px 0px 4px;
}
.mobileDeviceListPaper .userActionsColumn,
.mobileDeviceListPaper .userActionsColumnHeader {
  width: 10%;
}
.mobileDeviceListPaper .userActionsColumn div,
.mobileDeviceListPaper .userActionsColumnHeader div {
  font-size: 20px;
  color: #0064d2;
  font-weight: bold;
  padding: 0px 0px 0px 8px;
}
.mobileDeviceListPaper .assetDistanceColumnHeader div,
.mobileDeviceListPaper .lastSingalDateTimeColumnHeader div {
  font-size: 11px;
  color: #4a4a4a;
}
.mobileDeviceListPaper .mobileListDeviceFriendlyName {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.mobileDeviceListPaper .placeholderText {
  border-radius: 9px;
  line-height: 12px;
  background-color: #eeeeef;
  color: #eeeeef;
}

