.geozone-detail { 
  width: 607px;
  height: calc(100vh - 150px);
  overflow: auto;
  padding-right: 20px;
  cursor: pointer;
  vertical-align: top; 
 
  @media screen and (max-width: 1250px){
  width: 550px;
  }
   @media screen and (max-width: 1240px){
  width: 480px;
  }

  .geozone-detail-body {
    display: table;
    -webkit-border-vertical-spacing: 5px;
    -webkit-border-horizontal-spacing: 10px;
  }

  .geozone-detail-body-row {
    display: table-row;
    vertical-align: middle;
  }

  .geozone-detail-column {
    width: 50%;
    font-family: TelstraAkkurat, sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #414141;
    display: table-cell;
    vertical-align: top;
    max-width: 110px;
    text-align: left;
  }

  .geozone-detail-cell-heading {
    font-size: 12px;
    font-weight: bold;
    width: 60%;
  }

  .geozone-detail-cell-value {
    overflow: visible;
    text-overflow: ellipsis;
    width: auto;
  }

  .geozone-detail-title {
    width: 100%;
    height: 18px;
    background-color: #0064d2;

    font-family: TelstraAkkurat, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #ffffff;
    display: table;
  }

  .geozone-detail-title-id-label {
    font-weight: bold;
    display: table-cell;
    vertical-align: middle;
  }

  .geozone-detail-title-cell {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
    max-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
