@import '../styles/common.scss';

.upload-template-panel {
  .upload-template-component {
    button {
      height: 100%;
      width: 100%;
    }
    .statusMessage {
      height: 51px;
      white-space: pre-wrap;
      color: #000000;
      font-size: 15px;
      font-weight: bold;
    }
    .instruction {
      margin-top: 46px;
    }
    .content {
      padding: 28px 50px;
    }
    .dragActive {
      /* overlay a black mask when dragging over the frame */
      box-shadow: 0 0 5px 1px #0095f2;
      /* rejected coloring doesnt work properly in windows */
    }
  }
}
.downloadTemplatePanel {
  padding-top: 11px;
  .downloadCSVTemplateHelpText {
    font-size: 14px;
    color: #000000;
    padding-top: 5px;
    padding-bottom: 20px;
  }
  .downloadCSVTemplateWarningText {
    font-size: 14px;
    font-weight: bold;
    color: red;
    padding-top: 5px;
    padding-bottom: 20px;
  }
}
.dialogContent {
  padding-top: 20px;
}
