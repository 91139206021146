@import '../styles/common.scss';
/* Set Column and corresponding data field width */
/*Set !important to force width, else its overriden by inline width style */
.device-details-title-component {
  $line-height: 50px;
  display: flex;
  margin-left: 460px;

  .label {
    overflow-x: auto;
    padding-left: 23px;
    width: 101px;
    height: 26px;
    font-family: TelstraAkkurat, sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #414141;
  }
  .mobileId {
    display: inline-block;
    padding-left: 15px;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    width: 100%;
    height: 25px;
    font-family: TelstraAkkurat, sans-serif;
    font-size: 21px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #4a4a4a;
  }
  .batteryContainer {
    float: right;
    margin-left: 375px;
    margin-right: 24px;
    padding-left: 45px;
    .batteryText {
      width: 43px;
      height: 25px;
      font-family: TelstraAkkurat, sans-serif;
      font-size: 21px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0px;
      color: #4a4a4a;
    }
  }
}

.device-title-component {
  $line-height: 50px;
  display: block;
  background-color: $primary-color;
  height: $line-height;
  line-height: $line-height;
  color: #ffffff;
  font-size: 22px;
  .label {
    display: inline-block;
    font-weight: bold;
    padding-left: 23px;
    color: #ffffff;
  }
  .serialNumber {
    display: inline-block;
    padding-left: 23px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .batteryContainer {
    float: right;
    margin-right: 24px;

    .batteryText {
      font-size: 18px;
      margin-right: 8px;
      text-align: right;
    }
  }
}

.device-details-container {
  .deviceTitle {
    display: flex;
    margin-top: 15px;
    margin-left: 5px;
  }
  .tagsLabel {
    margin-bottom: 8px;
  }
  .detailsTextFieldHelperText,
  .message {
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
  }
  .notifications-settings {
    .settings-page {
      margin: 0;
    }
    .radioGroup {
      display: inline-block;
    }
  }
}

.profile-list-section,
.notifications-page,
.notifications-container {
  .alignContentLeft {
    padding-left: 0;
    justify-content: left;
  }
  .notifications-toggle-section {
    background: #f7fafe;
    margin: 0 -22px 22px -48px;
    padding: 0 48px 0 55px;
    .radioSection {
      padding-bottom: 8px;
      padding-top: 8px;
    }
    .boxed-content {
      box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.07);
      margin-left: -32px;
      margin-bottom: 20px;
      width: 80%;
      padding: 10px 0 0 30px;
    }

    .switchComponent {
      max-height: 22px;
      .enabledBlack {
        color: #000000;
      }
      .enabledBlue {
        color: #0064d2;
      }
      .enabledBlack,
      .enabledBlue {
        font-weight: bold;
      }
    }
  }
  .settings-page {
    .boxed-content {
      padding-left: 24px;
      .emptySpace{
        padding-bottom: 48px;
      }
    }
  }
}
.fullHeight {
  height: 100%;
}
.flexColumnGrow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.device-notes-component {
  display: flex;
  .underline {
    width: 70%;
    border-bottom: solid 2px #979797;
  }
}
.styledField.notes-text-field {
  div {
    padding-top: 0px;
  }
}

.device-details-component {
  max-height: 75vh;
  overflow-y: auto;
  margin: 0 1% 0 0%;
  font-size: 18px;
  label,
  .tagsLabel,
  #notes,
  .value {
    font-size: 18px;
    color: #000;
  }
  .device-details-geozones-dropdown p,
  .asset-id-text-field-item input {
    font-size: 18px;
    color: #0064d2;
    &:read-only {
      color: #000000;
    }
  }
  .non-notes-section {
    width: 66%;
    .label,
    label,
    .tagsLabel {
      font-weight: bold;
    }
    .value {
      color: #000;
    }
    .tag {
      color: #000;
      font-size: 16px;
    }
  }
  .notes-section {
    width: 90%;
    input {
      color: #000;
    }
    label {
      font-weight: bold;
    }
  }
  .singleTrackerMap {
    width: 100%;
    height: 80%;
    margin-top: 10px;
  }
  .truncateTagsForDetails {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines displayed before it truncate */
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    overflow: hidden !important;
    color: #0095f2;
    max-width: 280px;
    max-height: 70px;
  }
}
.isMobile .device-details-component {
  margin-right: 0px;
  width: 98%; //Mobile occupy most space
}

.tagDelete {
  cursor: pointer;
  float: right;
  padding-left: 5px;
}

.device-tags-component,
.bulk-assign-component {
  $left-col-width: auto;
  height: 100%;
  //text input
  input {
    height: 16px;
    font-size: 16px;
  }
  .dividerClass {
    border-bottom: 2px solid #e8e8e8;
    margin-top: 15px;
  }
  .new-tag-pane {
    height: 200px;
  }
  .messagePlaceHolder {
    margin-top: 0px;
    margin-bottom: 16px;
    padding-bottom: 0px;
  }
  .details-row-geozones-dropdown {
    padding-bottom: 16px; //override default details row padding of 24px as we display message
  }

  .applyTags {
    height: 19px;
    font-size: 15px;
    font-weight: bold;
    color: #414141;
    border: none;
    margin-top: 20px;
    cursor: pointer;
  }

  .message {
    height: 19px;
    font-size: 15px;
    font-weight: normal;
    border: none;
    margin-top: 20px;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #000000;
  }

  .device-tags-header,
  .bulk-assign-header {
    font-size: 22px;
    font-weight: bold;
    color: #414141;
    margin-bottom: 12px;
  }
  div.submit {
    float: right;
    margin-top: 63px;
    button {
      width: 138px;
      height: 40px;
      background-color: #414141;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      outline: none;
      cursor: pointer;
    }
  }

  div.tagsContainer {
    display: flex;
    .tagCell {
      margin-top: 8px;
      word-wrap: break-word;
      width: $left-col-width;
      max-height: 270px;
      overflow: auto;
      align-self: center;
    }
  }

  .errorMessage {
    height: 19px;
    font-size: 15px;
    font-weight: normal;
    color: red;
    border: none;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
  }
}
.bulk-assign-tags-input-container {
  width: 470px !important;
}
.zone-assets-list-container {
  height: 100%;
  flex-grow: 1;
  .ReactTable {
    padding-left: 0;
    padding-top: 0;
  }
}
.device-details-history-map-component {
  .tracker-history-popup {
    .tracker-history-popup-title {
      text-align: center;
      white-space: normal;
      max-height: 40px;
      height: fit-content;
      overflow: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .signalDateTime {
      display: inline-block;
    }
    .accuracy {
      color: #0064d1;
      border-left: solid 1px #bebebe;
      border-right: solid 1px #bebebe;
      font-weight: bold;
      font-size: 14px;
      padding: 0 16px;
      line-height: 25px;
    }
    .batteryWrapper {
      float: right;
    }
    .tracker-history-popup-body {
      font-size: 14px;
      color: #747474;
      padding: 0 5px;
      display: flex;
      flex: auto;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.mobile-history-filter-component {
  input[type='date']::-webkit-clear-button {
    display: none;
  }
  input[type='date'] {
    display: block;
    width: 97%;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
  }
}
.device-map {
  .google-map-reset-control {
    bottom: 8px !important;
    right: 8px !important;
  }
  .google-map-view-toggle-control {
    bottom: 8px !important;
    right: 56px !important;
  }
}

.device-map.isMobile,
.device-details-container {
  .google-map-view-toggle-control {
    /*Set !important to force placement, else its overriden by inline styles of map control */
    left: 15px !important;
    top: 10px !important;
  }
  .google-map-view-toggle-control.isMobile {
    left: unset !important;
    right: 3px !important;
  }
  .google-map-view-toggle-control.detailsView {
    //will apply only for details view in device details page, not history view
    bottom: 8px !important;
    right: 8px !important;
    left: unset !important;
    top: unset !important;
  }
}
.zone-details-container {
  .google-map-view-toggle-control {
    bottom: 8px !important;
    right: 8px !important;
  }
}
