@import '../../styles/common.scss';
/* Set Column and corresponding data field width */
/*Set !important to force width, else its overriden by inline width style */
.devicesFilterTitle {
  $line-height: 35px;
  display: block;
  background-color: $primary-color;
  height: $line-height;
  line-height: $line-height;

  padding-left: 23px;

  font-size: 15px;
  color: #ffffff;
}
.inactiveTrackersLabel,
.mapBoundaryFilterLabel {
  width: 8em;
}
.inactiveTrackersMapViewSwitch .filterSwitchRows {
  padding-bottom: 0px;
}
.devicesFilterComponent {
  //space between title and components
  padding: 16px;
  background-color: #fff;
  .filterSection {
    padding: 12px;
  }
  .userActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    //Label besides the switch element
    .inactiveTrackersLabel,
    .mapBoundaryFilterLabel {
      margin-right: 8px;
      color: #000000;
      font-size: 14px;
      font-weight: bold;
    }
    .mapBoundaryFilterSwitch {
      padding-bottom: 0px; //remove bottom padding for mapboundary switch. this is to align message texta and apply button
      padding-top: 0px;
    }
    .buttonsSection {
      display: flex;
      flex-direction: row;
      margin-right: 24px;
    }
    .message.error {
      color: red;
      font-weight: bold;
      padding: 0px 30px;
    }
    .filterSwitch {
      justify-content: space-between;
      min-width: 200px; //ensure switch doesnt reduces size even when error message is displayed
    }
  }
  .alignClearButton {
    div:first-child {
      display: flex;
    }
    #mui-component-select-deviceBatteryFilterComponent,
    #mui-component-select-deviceTypeFilterComponent,
    #mui-component-select-geoZonesFilterCondition,
    #mui-component-select-numericFilterComponent {
      width: 100%;
      padding-right: 0;
      z-index: 9;
      color: #0064d2;
    }
  }
}

.devicesFilterComponent.isMobile {
  display: flex;
  flex-direction: column;
  //flex-grow: 1;
  padding: 30px 16px 0px 16px;
  position: relative;
  overflow: scroll;
  height: 85%;

  @media (orientation: landscape) {
    position: absolute !important;
  }

  .filterSection {
    padding: 0px 15px 15px !important;

    @media (orientation: landscape) {
      padding: 0px 13px 13px !important;
    }
  }
  .includeInactiveTrackersWrapperMobile {
    margin-left: 0px;
    width: 100%;
    padding-bottom: 8px;
    align-items: start;
  }
  .inactiveTrackersLabelMobile {
    height: 12px;
    font-size: 18px;
    color: #000000;
    text-align: left;
    margin-bottom: 8px;
    display: flex;
    width: 45%;
    justify-content: flex-start;
  }
  .inactiveTrackerControlMobile {
    margin-top: 5px;
  }
}

.devicesFilterComponent.isMobile.isAndroid {
  height: 80%;
  @media (orientation: landscape) {
    position: absolute !important;
  }
}

.devicesFilterComponent.isMobile.showFilter {
  height: 70%;
  @media (orientation: landscape) {
    padding-top: 64px;
  }
}
.filterSection.high-contrast {
  .title {
    font-size: 18px;
  }
  .dateFilterComponent {
    .label,
    .disabled {
      font-size: 16px;
      color: #000000;
    }
    ::placeholder {
      color: #0064d2;
    }
  }
}
.inactiveTrackersMapViewSwitch > .toggle-label,
.inactiveTrackersMapViewSwitch .inactiveTrackersState {
  font-weight: bold;
}
.filterSectionComponent {
  $header_height: 16px;

  padding-right: 10px;
  border-right: solid 1px #dddddd;
  height: 100%; //ensure right border appears fully
  .header {
    height: $header_height;
  }
  .title,
  .clear {
    display: inline-block;
  }
  .clear {
    float: right;
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    color: #0095f2;
    height: $header_height;
    line-height: $header_height;
    button {
      /* Override common button small properties */
      font-size: 16px !important;
      line-height: $header_height;
      font-weight: bold !important;
      padding: 0px;
    }
  }
  .title {
    line-height: $header_height;
    float: left;
    color: #414141;
    height: 16px;
    font-size: 18px;
    font-weight: bold;
  }
  .title.isMobile {
    line-height: $header_height;
    float: left;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
  }
  .tagsContainer.mobile {
    position: relative;
    top: -38px;
    .tagCell {
      max-height: 290px; //ensure height is limited
      overflow-x: hidden;
      overflow-y: scroll;
    }
    @media only screen and (min-width: 768px) and (max-width: 1199px) and (orientation: portrait) {
      .tagCell {
        max-height: none;
      }
    }
  }
  .tagsContainer {
    .tagCell {
      word-break: break-word;
      max-height: 200px; //ensure height is limited
      overflow: auto;

      // width: $left-col-width;
      span {
        margin-top: 2px;
        word-wrap: normal;
        display: inline-block;
        //height: 20.4px; //putting height forces the box to be big
        border: solid 1.2px #747474;
        margin-right: 10px;
        padding: 2px 7px;

        &:hover {
          cursor: pointer;
        }
        &.isMobile {
          background-color: white;
        }
        &.truncateTags {
          max-width: calc(100% - 24px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 2px 5px 3px 0px;
        }
        &.selected {
          background-color: #4a90e2;
          border-color: #4a90e2;
          color: #fff;
        }
        &.selected.isMobile {
          background-color: #0099f8;
          border-color: #0099f8;
          color: #fff;
        }
        &.disabled {
          color: #dddddd;
          border-color: #dddddd;
          &:hover {
            cursor: auto;
          }
        }
      }
    }
  }
  .customArrow {
    display: inline-block;
    width: 20px;
    height: 14px;
    margin: 0 3px;
    vertical-align: middle;
    background-image: url('../../images/drop-down-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    outline: none;
  }
}
.filterSectionComponent.withNoBorder {
  border-right: 0px;
}
.filterSectionComponent.withBottomBorder {
  border-bottom: solid 1px #0064d2;
  padding-bottom: 5px;
  border-right: 0px;
  padding-right: 0px;
}
.tagsAndOrLabel {
  span:first-child {
    height: 32px;
  }
  margin-left: 0px !important;
  margin-right: 10px !important;
}
.filterComponent {
  margin-top: 21px;
  .label {
    height: 12px;
    font-size: 16px;
    color: #414141;
    text-align: left;
    margin-top: 21px;
    margin-bottom: 8px;
    font-weight: bold;
  }
  .label:first-child {
    margin-top: 0px;
  }
  .label.disabled {
    color: #dddddd;
  }
}

.filterComponentMobile {
  margin-top: 0px;
  .label {
    height: 12px;
    font-size: 18px;
    color: #000000;
    text-align: left;
    margin-top: 21px;
    margin-bottom: 8px;
    display: flex;
    width: 45%;
    justify-content: flex-start;
  }
  .label:first-child {
    margin-top: 0px;
  }
  .label.disabled {
    color: #dddddd;
  }
}

.filterComponentMobile {
  .tagsLabel {
    height: 12px;
    font-size: 13px;
    color: #414141;
  }
}

.deviceTypeFilterComponent,
.deviceBatteryFilterComponent,
.numericFilterComponent {
  margin-top: 21px;
  .label {
    height: 12px;
    font-weight: bold;
    font-size: 16px;
    color: #414141;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 8px;
  }
  .label.disabled {
    color: #dddddd;
  }
}
//Select component - placeholder text should be disabled
.filterSelect.disabled {
  > div {
    //Select placeholder
    color: #dddddd;
  }
}
.numericFilterComponentMobile {
  display: flex;
  width: 100%;
  margin-top: 10px;
  .label {
    height: 12px;
    font-size: 18px;
    color: #000000;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 8px;
    display: flex;
    width: 45%;
    justify-content: flex-start;
  }
  .label.disabled {
    color: #dddddd;
  }
}

.numericFilterInput {
  padding: 6px 0 7px !important;
  color: #0064d2 !important;
}

.numericFilterInputMobile {
  padding: 12px 0 13px !important;
  text-align: center;
  &#number-input {
    width: 80px;
  }
}

.dateFilterComponent {
  .message {
    height: 19px;
    font-size: 13px;
    font-weight: normal;
    color: red;
    border: none;
    margin-top: 20px;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
  }
  .react-datepicker-popper {
    z-index: 99;
  }
  .react-datepicker__input-container input {
    color: #0064d2;
  }
  &.disabled {
    input::placeholder {
      color: #dddddd;
    }
  }
}
.geozones-filter-component-dropdown {
  margin-top: 3px; //to make same height as html dropdown
}
