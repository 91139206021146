@import '../styles/common.scss';
/* Set Column and corresponding data field width */
.mobileDeviceListPaper {
  .deviceFriendlyNameColumnHeader,
  .deviceFriendlyNameColumn {
    width: 47%;
  }
  .deviceFriendlyNameColumn,
  .deviceFriendlyNameColumnHeader {
    & div {
      width: 100%;
      font-weight: bold;
      font-size: 13px;
      padding: 0px 0px 0px 24px;
    }
  }

  .assetDistanceColumn,
  .assetDistanceColumnHeader {
    width: 18%;
  }
  .assetDistanceColumn,
  .assetDistanceColumnHeader {
    & div {
      font-size: 13px;
      color: #747474;
      padding: 0px 0px 0px 4px;
    }
  }

  .lastSingalDateTimeColumn,
  .lastSingalDateTimeColumnHeader {
    width: 25%;
  }
  .lastSingalDateTimeColumn,
  .lastSingalDateTimeColumnHeader {
    & div {
      font-size: 13px;
      color: #747474;
      padding: 0px 0px 0px 4px;
    }

    /* Placeholders shown in virtualized table when loading initial set of rows */
  }

  .userActionsColumn,
  .userActionsColumnHeader {
    width: 10%;
  }
  .userActionsColumn,
  .userActionsColumnHeader {
    & div {
      font-size: 20px;
      color: #0064d2;
      font-weight: bold;
      padding: 0px 0px 0px 8px;
    }
  }
  .assetDistanceColumnHeader,
  .lastSingalDateTimeColumnHeader {
    & div {
      font-size: 11px;
      color: #4a4a4a;
    }
  }
  .mobileListDeviceFriendlyName {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .placeholderText {
    border-radius: 9px;
    line-height: 12px;
    background-color: #eeeeef;
    color: #eeeeef;
  }
}
