/* Set Column and corresponding data field width */
/*Set !important to force width, else its overriden by inline width style */
.device-details-title-component {
  display: flex;
  margin-left: 460px;
}
.device-details-title-component .label {
  overflow-x: auto;
  padding-left: 23px;
  width: 101px;
  height: 26px;
  font-family: TelstraAkkurat, sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #414141;
}
.device-details-title-component .mobileId {
  display: inline-block;
  padding-left: 15px;
  width: 100%;
  height: 25px;
  font-family: TelstraAkkurat, sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #4a4a4a;
}
.device-details-title-component .batteryContainer {
  float: right;
  margin-left: 375px;
  margin-right: 24px;
  padding-left: 45px;
}
.device-details-title-component .batteryContainer .batteryText {
  width: 43px;
  height: 25px;
  font-family: TelstraAkkurat, sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #4a4a4a;
}

.device-title-component {
  display: block;
  background-color: #0064d2;
  height: 50px;
  line-height: 50px;
  color: #ffffff;
  font-size: 22px;
}
.device-title-component .label {
  display: inline-block;
  font-weight: bold;
  padding-left: 23px;
  color: #ffffff;
}
.device-title-component .serialNumber {
  display: inline-block;
  padding-left: 23px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.device-title-component .batteryContainer {
  float: right;
  margin-right: 24px;
}
.device-title-component .batteryContainer .batteryText {
  font-size: 18px;
  margin-right: 8px;
  text-align: right;
}

.device-details-container .deviceTitle {
  display: flex;
  margin-top: 15px;
  margin-left: 5px;
}
.device-details-container .tagsLabel {
  margin-bottom: 8px;
}
.device-details-container .detailsTextFieldHelperText,
.device-details-container .message {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}
.device-details-container .notifications-settings .settings-page {
  margin: 0;
}
.device-details-container .notifications-settings .radioGroup {
  display: inline-block;
}

.profile-list-section .alignContentLeft,
.notifications-page .alignContentLeft,
.notifications-container .alignContentLeft {
  padding-left: 0;
  justify-content: left;
}
.profile-list-section .notifications-toggle-section,
.notifications-page .notifications-toggle-section,
.notifications-container .notifications-toggle-section {
  background: #f7fafe;
  margin: 0 -22px 22px -48px;
  padding: 0 48px 0 55px;
}
.profile-list-section .notifications-toggle-section .radioSection,
.notifications-page .notifications-toggle-section .radioSection,
.notifications-container .notifications-toggle-section .radioSection {
  padding-bottom: 8px;
  padding-top: 8px;
}
.profile-list-section .notifications-toggle-section .boxed-content,
.notifications-page .notifications-toggle-section .boxed-content,
.notifications-container .notifications-toggle-section .boxed-content {
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.07);
  margin-left: -32px;
  margin-bottom: 20px;
  width: 80%;
  padding: 10px 0 0 30px;
}
.profile-list-section .notifications-toggle-section .switchComponent,
.notifications-page .notifications-toggle-section .switchComponent,
.notifications-container .notifications-toggle-section .switchComponent {
  max-height: 22px;
}
.profile-list-section .notifications-toggle-section .switchComponent .enabledBlack,
.notifications-page .notifications-toggle-section .switchComponent .enabledBlack,
.notifications-container .notifications-toggle-section .switchComponent .enabledBlack {
  color: #000000;
}
.profile-list-section .notifications-toggle-section .switchComponent .enabledBlue,
.notifications-page .notifications-toggle-section .switchComponent .enabledBlue,
.notifications-container .notifications-toggle-section .switchComponent .enabledBlue {
  color: #0064d2;
}
.profile-list-section .notifications-toggle-section .switchComponent .enabledBlack,
.profile-list-section .notifications-toggle-section .switchComponent .enabledBlue,
.notifications-page .notifications-toggle-section .switchComponent .enabledBlack,
.notifications-page .notifications-toggle-section .switchComponent .enabledBlue,
.notifications-container .notifications-toggle-section .switchComponent .enabledBlack,
.notifications-container .notifications-toggle-section .switchComponent .enabledBlue {
  font-weight: bold;
}
.profile-list-section .settings-page .boxed-content,
.notifications-page .settings-page .boxed-content,
.notifications-container .settings-page .boxed-content {
  padding-left: 24px;
}
.profile-list-section .settings-page .boxed-content .emptySpace,
.notifications-page .settings-page .boxed-content .emptySpace,
.notifications-container .settings-page .boxed-content .emptySpace {
  padding-bottom: 48px;
}

.fullHeight {
  height: 100%;
}

.flexColumnGrow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.device-notes-component {
  display: flex;
}
.device-notes-component .underline {
  width: 70%;
  border-bottom: solid 2px #979797;
}

.styledField.notes-text-field div {
  padding-top: 0px;
}

.device-details-component {
  max-height: 75vh;
  overflow-y: auto;
  margin: 0 1% 0 0%;
  font-size: 18px;
}
.device-details-component label,
.device-details-component .tagsLabel,
.device-details-component #notes,
.device-details-component .value {
  font-size: 18px;
  color: #000;
}
.device-details-component .device-details-geozones-dropdown p,
.device-details-component .asset-id-text-field-item input {
  font-size: 18px;
  color: #0064d2;
}
.device-details-component .device-details-geozones-dropdown p:read-only,
.device-details-component .asset-id-text-field-item input:read-only {
  color: #000000;
}
.device-details-component .non-notes-section {
  width: 66%;
}
.device-details-component .non-notes-section .label,
.device-details-component .non-notes-section label,
.device-details-component .non-notes-section .tagsLabel {
  font-weight: bold;
}
.device-details-component .non-notes-section .value {
  color: #000;
}
.device-details-component .non-notes-section .tag {
  color: #000;
  font-size: 16px;
}
.device-details-component .notes-section {
  width: 90%;
}
.device-details-component .notes-section input {
  color: #000;
}
.device-details-component .notes-section label {
  font-weight: bold;
}
.device-details-component .singleTrackerMap {
  width: 100%;
  height: 80%;
  margin-top: 10px;
}
.device-details-component .truncateTagsForDetails {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines displayed before it truncate */
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  color: #0095f2;
  max-width: 280px;
  max-height: 70px;
}

.isMobile .device-details-component {
  margin-right: 0px;
  width: 98%;
}

.tagDelete {
  cursor: pointer;
  float: right;
  padding-left: 5px;
}

.device-tags-component,
.bulk-assign-component {
  height: 100%;
}
.device-tags-component input,
.bulk-assign-component input {
  height: 16px;
  font-size: 16px;
}
.device-tags-component .dividerClass,
.bulk-assign-component .dividerClass {
  border-bottom: 2px solid #e8e8e8;
  margin-top: 15px;
}
.device-tags-component .new-tag-pane,
.bulk-assign-component .new-tag-pane {
  height: 200px;
}
.device-tags-component .messagePlaceHolder,
.bulk-assign-component .messagePlaceHolder {
  margin-top: 0px;
  margin-bottom: 16px;
  padding-bottom: 0px;
}
.device-tags-component .details-row-geozones-dropdown,
.bulk-assign-component .details-row-geozones-dropdown {
  padding-bottom: 16px;
}
.device-tags-component .applyTags,
.bulk-assign-component .applyTags {
  height: 19px;
  font-size: 15px;
  font-weight: bold;
  color: #414141;
  border: none;
  margin-top: 20px;
  cursor: pointer;
}
.device-tags-component .message,
.bulk-assign-component .message {
  height: 19px;
  font-size: 15px;
  font-weight: normal;
  border: none;
  margin-top: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #000000;
}
.device-tags-component .device-tags-header,
.device-tags-component .bulk-assign-header,
.bulk-assign-component .device-tags-header,
.bulk-assign-component .bulk-assign-header {
  font-size: 22px;
  font-weight: bold;
  color: #414141;
  margin-bottom: 12px;
}
.device-tags-component div.submit,
.bulk-assign-component div.submit {
  float: right;
  margin-top: 63px;
}
.device-tags-component div.submit button,
.bulk-assign-component div.submit button {
  width: 138px;
  height: 40px;
  background-color: #414141;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  outline: none;
  cursor: pointer;
}
.device-tags-component div.tagsContainer,
.bulk-assign-component div.tagsContainer {
  display: flex;
}
.device-tags-component div.tagsContainer .tagCell,
.bulk-assign-component div.tagsContainer .tagCell {
  margin-top: 8px;
  word-wrap: break-word;
  width: auto;
  max-height: 270px;
  overflow: auto;
  align-self: center;
}
.device-tags-component .errorMessage,
.bulk-assign-component .errorMessage {
  height: 19px;
  font-size: 15px;
  font-weight: normal;
  color: red;
  border: none;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
}

.bulk-assign-tags-input-container {
  width: 470px !important;
}

.zone-assets-list-container {
  height: 100%;
  flex-grow: 1;
}
.zone-assets-list-container .ReactTable {
  padding-left: 0;
  padding-top: 0;
}

.device-details-history-map-component .tracker-history-popup .tracker-history-popup-title {
  text-align: center;
  white-space: normal;
  max-height: 40px;
  height: fit-content;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.device-details-history-map-component .tracker-history-popup .signalDateTime {
  display: inline-block;
}
.device-details-history-map-component .tracker-history-popup .accuracy {
  color: #0064d1;
  border-left: solid 1px #bebebe;
  border-right: solid 1px #bebebe;
  font-weight: bold;
  font-size: 14px;
  padding: 0 16px;
  line-height: 25px;
}
.device-details-history-map-component .tracker-history-popup .batteryWrapper {
  float: right;
}
.device-details-history-map-component .tracker-history-popup .tracker-history-popup-body {
  font-size: 14px;
  color: #747474;
  padding: 0 5px;
  display: flex;
  flex: auto;
  justify-content: space-between;
  align-items: center;
}

.mobile-history-filter-component input[type=date]::-webkit-clear-button {
  display: none;
}
.mobile-history-filter-component input[type=date] {
  display: block;
  width: 97%;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}
.mobile-history-filter-component input[type=date]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
}

.device-map .google-map-reset-control {
  bottom: 8px !important;
  right: 8px !important;
}
.device-map .google-map-view-toggle-control {
  bottom: 8px !important;
  right: 56px !important;
}

.device-map.isMobile .google-map-view-toggle-control,
.device-details-container .google-map-view-toggle-control {
  /*Set !important to force placement, else its overriden by inline styles of map control */
  left: 15px !important;
  top: 10px !important;
}
.device-map.isMobile .google-map-view-toggle-control.isMobile,
.device-details-container .google-map-view-toggle-control.isMobile {
  left: unset !important;
  right: 3px !important;
}
.device-map.isMobile .google-map-view-toggle-control.detailsView,
.device-details-container .google-map-view-toggle-control.detailsView {
  bottom: 8px !important;
  right: 8px !important;
  left: unset !important;
  top: unset !important;
}

.zone-details-container .google-map-view-toggle-control {
  bottom: 8px !important;
  right: 8px !important;
}

