@import 'common.scss';
@font-face {
  font-family: 'TELSTRAAKKURAT';
  src: url(./fonts/TELSTRAAKKURAT-REGULAR.EOT); /*  IE9 Compat Modes  */
  src: url(./fonts/TELSTRAAKKURAT-REGULAR.WOFF2) format('woff2'),
    url(./fonts/TELSTRAAKKURAT-REGULAR.OTF) format('opentype'),
    url(./fonts/TELSTRAAKKURAT-REGULAR.WOFF) format('woff'),
    url(./fonts/TELSTRAAKKURAT-REGULAR.TTF) format('truetype'),
    url(./fonts/TELSTRAAKKURAT-REGULAR.SVG) format('svg');
  font-weight: normal;
}
@font-face {
  font-family: 'TELSTRAAKKURAT';
  src: url(./fonts/TELSTRAAKKURAT-BOLD.EOT);
  src: url(./fonts/TELSTRAAKKURAT-BOLD.WOFF2) format('woff2'),
    url(./fonts/TELSTRAAKKURAT-BOLD.OTF) format('opentype'),
    url(./fonts/TELSTRAAKKURAT-BOLD.WOFF) format('woff'),
    url(./fonts/TELSTRAAKKURAT-BOLD.TTF) format('truetype'),
    url(./fonts/TELSTRAAKKURAT-BOLD.SVG) format('svg');
  font-weight: bold;
}
@font-face {
  font-family: 'TELSTRAAKKURAT';
  src: url(./fonts/TELSTRAAKKURAT-LIGHT.EOT);
  src: url(./fonts/TELSTRAAKKURAT-LIGHT.WOFF2) format('woff2'),
    url(./fonts/TELSTRAAKKURAT-LIGHT.OTF) format('opentype'),
    url(./fonts/TELSTRAAKKURAT-LIGHT.WOFF) format('woff'),
    url(./fonts/TELSTRAAKKURAT-LIGHT.TTF) format('truetype'),
    url(./fonts/TELSTRAAKKURAT-LIGHT.SVG) format('svg');
  font-weight: 300;
}

@mixin FullHeightFlexBox {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.App {
  text-align: center;
}
div#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.pageWithBackground {
  background-image: url(../images/background.png);
  background-image: -webkit-image-set(
    url(../images/background.png) 1x,
    url(../images/background@2x.png) 2x,
    url(../images/background@3x.png) 3x
  );
  background-image: image-set(
    url(../images/background.png) 1x,
    url(../images/background@2x.png) 2x,
    url(../images/background@3x.png) 3x
  );
  background-size: cover;
}
.logo {
  padding-bottom: 60px;
}
.logo-img {
  display: inline-block;
  padding-left: 53px;
  img {
    height: 65px;
  }
}
.logo-text {
  width: 70px;
  color: $primary-color;
  font-family: $primary-font-family;
  padding-left: 20px;
  display: inline-block;
  font-size: 21px;
}

.contentPane {
  box-sizing: border-box;
  @include FullHeightFlexBox;
}
.dashboard {
  width: 100%;
  height: 100%;
}
//IMPORTANT:
//Below series of styles applied to enable flex styling till Map/List views
//This is required so that map/list view occupy full height of the conent pane
//with out need to specify height in pixes or %ages

.mapView.mobile.fullScreen {
  @include FullHeightFlexBox;
  & > div {
    @include FullHeightFlexBox;
  }
  & .resizableMapSection {
    @include FullHeightFlexBox;
  }
  & .collapseContainer {
    @include FullHeightFlexBox;
  }
  & .collapseMapLoading {
    @include FullHeightFlexBox;
    > div {
      @include FullHeightFlexBox;
      > div {
        @include FullHeightFlexBox;
      }
    }
  }
  & .collapseMap {
    @include FullHeightFlexBox;
    > div {
      @include FullHeightFlexBox;
      > div {
        @include FullHeightFlexBox;
      }
    }
  }
}
//IMPORTANT:
//Below series of styles applied to enable flex to device list in mobile scenarios
.routesWithMap.mobile .dashboardContent {
  //By default, set flex-grow =0 for dashboardContent, in case of mobile
  //Only when rendered full screen, set flex-grow to 1 (refer next stley section )
  flex-grow: 0;
}
.routesWithMap .navPane .top {
  z-index: 3;
}
.dashboardContentTable,
.geozonesListContainer {
  @include FullHeightFlexBox;
}
.routesWithMap.mobile .dashboardContent.fullScreen {
  @include FullHeightFlexBox;

  .dashboardContentTable,
  .listView,
  .mobileDevicelist,
  .mobileDeviceListPaper,
  ._loading_overlay_wrapper {
    @include FullHeightFlexBox;
  }
  .mobileDeviceListCollapse {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    @include FullHeightFlexBox;
    > div {
      @include FullHeightFlexBox;
      > div {
        @include FullHeightFlexBox;
      }
    }
  }
  .listView {
    margin-top: 1px;
  }
}
.deviceListView {
  //display: table-row;
  background-color: #000000;
}
.wrapper div {
  align-items: stretch;
}

.version {
  position: absolute;
  bottom: 5px;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 8px;
}

.flexCenter {
  align-self: center;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body,
input,
textarea,
button {
  font-family: $primary-font-family;
}

.gradientbar {
  height: 5px;
  width: 100%;
  background: rgba(79, 169, 221, 1);
  background: -moz-linear-gradient(
    left,
    rgba(79, 169, 221, 1) 0%,
    rgba(114, 82, 145, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(79, 169, 221, 1)),
    color-stop(100%, rgba(114, 82, 145, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(79, 169, 221, 1) 0%,
    rgba(114, 82, 145, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(79, 169, 221, 1) 0%,
    rgba(114, 82, 145, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(79, 169, 221, 1) 0%,
    rgba(114, 82, 145, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(79, 169, 221, 1) 0%,
    rgba(114, 82, 145, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4fa9dd', endColorstr='#725291', GradientType=1 );
}
.react-datepicker__input-container {
  &:hover {
    &:before {
      border-bottom: 2px solid #000;
    }
  }
  &:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: '\00a0';
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
  }
  &:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid red;
    pointer-events: none;
  }
  input {
    width: 100%;
    font: inherit;
    color: #9b9b9b;
    border: 0;
    margin: 0;
    padding: 6px 0 7px;
    display: block;
    min-width: 0;
    flex-grow: 1;
    box-sizing: content-box;
    background: none;
    -webkit-tap-highlight-color: transparent;
    &:placeholder {
      color: #9b9b9b;
    }
    &:focus {
      outline: none;
    }
  }
}
.react-datepicker__time-list {
  padding: 0;
  width: auto;
  height: auto;
}
.react-datepicker__header--time {
  padding-top: 2.5em !important;
}
.contentPaneContainer,
div.indexPage,
div.app,
div.routesWithMap {
  @include FullHeightFlexBox;
  position: relative; //added, so that child NavPane ( assets, geozones tab) can be positioned absolute
}

div.version-component {
  color: #9b9b9b;
  margin-right: 8px;
}
//custom zone edge point styles
.zone-map div[style*='border-radius: 6px; background-color'],
.zone-map div[style*='border-radius: 6px; border-color'] {
  border-width: 0px !important;
  border-style: hidden !important;
  background-color: transparent !important;
}
div.zone-map-set-control,
div.zone-map-clear-control {
  top: 10px !important;
}
div.zone-map-tooltip {
  padding: 6px 0px;
  width: 240px;
  text-align: center;
  display: block;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: #414141;
  box-shadow: 0px 3px 6px #00000033;
  background-color: white;
  border-radius: 5px;
  > br {
    content: ' ';
    display: block;
    margin: 5px 0px;
  }
}
.root-overflow {
  overflow: hidden;
}
.custom-appControlsContainer {
  position: fixed;
  width: 100%;
  z-index: 2;
}

.react-horizontal-scrolling-menu--wrapper {
  max-width: 100%;
}

/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 100% !important;
}

.react-horizontal-scrolling-menu--separator {
  margin: 5px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.skipLink {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  z-index: 207; // to be greater than its sibling overlay i.e 206
  text-decoration: underline;
  top: 0;
  left: 1.5rem;
  &:focus {
    color: #0064d2;
    height: auto;
    width: auto;
    margin: 14px 0px;
    clip: auto;
    display: inline-flex;
    position: fixed;
    border-radius: 3px;
    top: 5px;
    padding: 2px;
    box-shadow: 0 0 0 2px #0064D2,0 0 0 4px #B2E0FD;
    outline: none;

    + .skipLinkSiblingOverlay {
      background-color: white;
      position: absolute;
      width: 60%;
      height: 50px;
      z-index: 206; // to be greater than searchbox i.e 205
    }
  }
}
