@charset "UTF-8";
@font-face {
  font-family: "TELSTRAAKKURAT";
  src: url(./fonts/TELSTRAAKKURAT-REGULAR.EOT); /*  IE9 Compat Modes  */
  src: url(./fonts/TELSTRAAKKURAT-REGULAR.WOFF2) format("woff2"), url(./fonts/TELSTRAAKKURAT-REGULAR.OTF) format("opentype"), url(./fonts/TELSTRAAKKURAT-REGULAR.WOFF) format("woff"), url(./fonts/TELSTRAAKKURAT-REGULAR.TTF) format("truetype"), url(./fonts/TELSTRAAKKURAT-REGULAR.SVG) format("svg");
  font-weight: normal;
}
@font-face {
  font-family: "TELSTRAAKKURAT";
  src: url(./fonts/TELSTRAAKKURAT-BOLD.EOT);
  src: url(./fonts/TELSTRAAKKURAT-BOLD.WOFF2) format("woff2"), url(./fonts/TELSTRAAKKURAT-BOLD.OTF) format("opentype"), url(./fonts/TELSTRAAKKURAT-BOLD.WOFF) format("woff"), url(./fonts/TELSTRAAKKURAT-BOLD.TTF) format("truetype"), url(./fonts/TELSTRAAKKURAT-BOLD.SVG) format("svg");
  font-weight: bold;
}
@font-face {
  font-family: "TELSTRAAKKURAT";
  src: url(./fonts/TELSTRAAKKURAT-LIGHT.EOT);
  src: url(./fonts/TELSTRAAKKURAT-LIGHT.WOFF2) format("woff2"), url(./fonts/TELSTRAAKKURAT-LIGHT.OTF) format("opentype"), url(./fonts/TELSTRAAKKURAT-LIGHT.WOFF) format("woff"), url(./fonts/TELSTRAAKKURAT-LIGHT.TTF) format("truetype"), url(./fonts/TELSTRAAKKURAT-LIGHT.SVG) format("svg");
  font-weight: 300;
}
.App {
  text-align: center;
}

div#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.pageWithBackground {
  background-image: url(../images/background.png);
  background-image: -webkit-image-set(url(../images/background.png) 1x, url(../images/background@2x.png) 2x, url(../images/background@3x.png) 3x);
  background-image: image-set(url(../images/background.png) 1x, url(../images/background@2x.png) 2x, url(../images/background@3x.png) 3x);
  background-size: cover;
}

.logo {
  padding-bottom: 60px;
}

.logo-img {
  display: inline-block;
  padding-left: 53px;
}
.logo-img img {
  height: 65px;
}

.logo-text {
  width: 70px;
  color: #0064d2;
  font-family: "TELSTRAAKKURAT", Arial, Helvetica, sans-serif;
  padding-left: 20px;
  display: inline-block;
  font-size: 21px;
}

.contentPane {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

.dashboard {
  width: 100%;
  height: 100%;
}

.mapView.mobile.fullScreen {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.mapView.mobile.fullScreen > div {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.mapView.mobile.fullScreen .resizableMapSection {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.mapView.mobile.fullScreen .collapseContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.mapView.mobile.fullScreen .collapseMapLoading {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.mapView.mobile.fullScreen .collapseMapLoading > div {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.mapView.mobile.fullScreen .collapseMapLoading > div > div {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.mapView.mobile.fullScreen .collapseMap {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.mapView.mobile.fullScreen .collapseMap > div {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.mapView.mobile.fullScreen .collapseMap > div > div {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

.routesWithMap.mobile .dashboardContent {
  flex-grow: 0;
}

.routesWithMap .navPane .top {
  z-index: 3;
}

.dashboardContentTable,
.geozonesListContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

.routesWithMap.mobile .dashboardContent.fullScreen {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.routesWithMap.mobile .dashboardContent.fullScreen .dashboardContentTable,
.routesWithMap.mobile .dashboardContent.fullScreen .listView,
.routesWithMap.mobile .dashboardContent.fullScreen .mobileDevicelist,
.routesWithMap.mobile .dashboardContent.fullScreen .mobileDeviceListPaper,
.routesWithMap.mobile .dashboardContent.fullScreen ._loading_overlay_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.routesWithMap.mobile .dashboardContent.fullScreen .mobileDeviceListCollapse {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.routesWithMap.mobile .dashboardContent.fullScreen .mobileDeviceListCollapse > div {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.routesWithMap.mobile .dashboardContent.fullScreen .mobileDeviceListCollapse > div > div {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.routesWithMap.mobile .dashboardContent.fullScreen .listView {
  margin-top: 1px;
}

.deviceListView {
  background-color: #000000;
}

.wrapper div {
  align-items: stretch;
}

.version {
  position: absolute;
  bottom: 5px;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 8px;
}

.flexCenter {
  align-self: center;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body,
input,
textarea,
button {
  font-family: "TELSTRAAKKURAT", Arial, Helvetica, sans-serif;
}

.gradientbar {
  height: 5px;
  width: 100%;
  background: rgb(79, 169, 221);
  background: -moz-linear-gradient(left, rgb(79, 169, 221) 0%, rgb(114, 82, 145) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgb(79, 169, 221)), color-stop(100%, rgb(114, 82, 145)));
  background: -webkit-linear-gradient(left, rgb(79, 169, 221) 0%, rgb(114, 82, 145) 100%);
  background: -o-linear-gradient(left, rgb(79, 169, 221) 0%, rgb(114, 82, 145) 100%);
  background: -ms-linear-gradient(left, rgb(79, 169, 221) 0%, rgb(114, 82, 145) 100%);
  background: linear-gradient(to right, rgb(79, 169, 221) 0%, rgb(114, 82, 145) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#4fa9dd", endColorstr="#725291", GradientType=1 );
}

.react-datepicker__input-container:hover:before {
  border-bottom: 2px solid #000;
}
.react-datepicker__input-container:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: " ";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}
.react-datepicker__input-container:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid red;
  pointer-events: none;
}
.react-datepicker__input-container input {
  width: 100%;
  font: inherit;
  color: #9b9b9b;
  border: 0;
  margin: 0;
  padding: 6px 0 7px;
  display: block;
  min-width: 0;
  flex-grow: 1;
  box-sizing: content-box;
  background: none;
  -webkit-tap-highlight-color: transparent;
}
.react-datepicker__input-container input:placeholder {
  color: #9b9b9b;
}
.react-datepicker__input-container input:focus {
  outline: none;
}

.react-datepicker__time-list {
  padding: 0;
  width: auto;
  height: auto;
}

.react-datepicker__header--time {
  padding-top: 2.5em !important;
}

.contentPaneContainer,
div.indexPage,
div.app,
div.routesWithMap {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  position: relative;
}

div.version-component {
  color: #9b9b9b;
  margin-right: 8px;
}

.zone-map div[style*="border-radius: 6px; background-color"],
.zone-map div[style*="border-radius: 6px; border-color"] {
  border-width: 0px !important;
  border-style: hidden !important;
  background-color: transparent !important;
}

div.zone-map-set-control,
div.zone-map-clear-control {
  top: 10px !important;
}

div.zone-map-tooltip {
  padding: 6px 0px;
  width: 240px;
  text-align: center;
  display: block;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: #414141;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 5px;
}
div.zone-map-tooltip > br {
  content: " ";
  display: block;
  margin: 5px 0px;
}

.root-overflow {
  overflow: hidden;
}

.custom-appControlsContainer {
  position: fixed;
  width: 100%;
  z-index: 2;
}

.react-horizontal-scrolling-menu--wrapper {
  max-width: 100%;
}

/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 100% !important;
}

.react-horizontal-scrolling-menu--separator {
  margin: 5px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.skipLink {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  z-index: 207;
  text-decoration: underline;
  top: 0;
  left: 1.5rem;
}
.skipLink:focus {
  color: #0064d2;
  height: auto;
  width: auto;
  margin: 14px 0px;
  clip: auto;
  display: inline-flex;
  position: fixed;
  border-radius: 3px;
  top: 5px;
  padding: 2px;
  box-shadow: 0 0 0 2px #0064D2, 0 0 0 4px #B2E0FD;
  outline: none;
}
.skipLink:focus + .skipLinkSiblingOverlay {
  background-color: white;
  position: absolute;
  width: 60%;
  height: 50px;
  z-index: 206;
}

